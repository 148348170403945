/* eslint-disable react/forbid-prop-types */
import React, { Component, Suspense } from 'react';
import classNames from 'classnames';
import {
  any, arrayOf, bool, func, number, oneOf, oneOfType, shape, string
} from 'prop-types';
import {
  Col, Row, Image, Placeholder
} from '@thd-olt-component-react/core-ui';
import { PaintSwatches } from '@one-thd/sui-icons';
import {
  ARIcon, BackgroundCircleIcon, HotspotTagIcon, PlayIcon, ThreeSixtyIcon
} from '../Icons';
import { ReactImageMagnify } from '../ReactImageMagnify/ReactImageMagnify';
import './media-gallery-desktop.style.scss';
import Helper from '../../Helper';
import { mediaClick, thumbnailClick } from '../../../publisher';

const MediaGalleryOverlay = React.lazy(() => import(
  /* webpackChunkName: "media-gallery-overlay" */'../MediaGalleryOverlay')
);

class MediaGalleryDesktop extends Component {
  constructor(props) {
    super(props);
    this.mainImageRef = React.createRef();
    this.mainImageWrapperRef = React.createRef();
    this.thumpRef = React.createRef();
  }

  state = {
    mainImage: null,
    overlayOpened: false,
    selectedThumbnailIndex: 0,
    thumbnails: []
  };

  componentDidMount = () => {
    const { media = {}, showAvataarAR, augmentedRealityIndex } = this.props;
    const { thumbnails = [] } = media;

    this.setState({ thumbnails }); // es-lint-disable-line react/no-did-update-set-state

    if (showAvataarAR) {
      this.setState({ selectedThumbnailIndex: augmentedRealityIndex, overlayOpened: true });
    }
  };

  componentDidUpdate(prevProps) {
    const {
      media = {}, mainSuperSkuHover = [], showAvataarAR = false, augmentedRealityIndex = 0
    } = this.props;
    const { main = [] } = media;
    if ((prevProps?.media && prevProps?.media.main[0] && prevProps.media.main[0]?.url !== main[0]?.url)) {
      this.updatePageLayout();
    }
    if ((prevProps?.media && prevProps?.media.main !== main)) {
      this.updatePageLayout();
    }
    if (mainSuperSkuHover?.length && prevProps?.mainSuperSkuHover !== mainSuperSkuHover) {
      this.updatePageSuperSkuHoverLayout();
    }
    if (prevProps?.media && prevProps?.mainSuperSkuHover?.length && !mainSuperSkuHover?.length) {
      this.updatePagePostSuperSkuHoverLayout();
    }
    if (prevProps?.showAvataarAR !== showAvataarAR && showAvataarAR) {
      this.setState({
        selectedThumbnailIndex: augmentedRealityIndex,
        mainImage: main[augmentedRealityIndex],
        overlayOpened: true
      });
    }
  }

  updatePageLayout = () => {
    const { media = {} } = this.props;
    const { main, thumbnails } = media;

    this.setState({
      mainImage: main[0],
      selectedThumbnailIndex: 0,
      thumbnails
    });
  }

  updatePagePostSuperSkuHoverLayout = () => {
    const { media = {} } = this.props;
    const { main, thumbnails } = media;
    const { selectedThumbnailIndex } = this.state;
    this.setState({
      mainImage: main[selectedThumbnailIndex],
      thumbnails
    });

  }

  updatePageSuperSkuHoverLayout = () => {
    const { media = {}, mainSuperSkuHover = [] } = this.props;
    const { main } = media;
    const { selectedThumbnailIndex } = this.state;
    const index = mainSuperSkuHover.findIndex((t) => t.type === main[selectedThumbnailIndex]?.type);
    const isImage = Helper.isImage({ media: mainSuperSkuHover[index] });
    this.setState({
      mainImage: (isImage && index > 0) ? mainSuperSkuHover[index] : mainSuperSkuHover[0]
    });
  }

  onMainImageClick = (thumbnailType) => {
    const payload = {
      eventName: 'expanded-view',
      primaryCategory: 'overlay'
    };
    thumbnailClick(payload);

    const { augmentedRealityIndex } = this.props;
    const { selectedThumbnailIndex, mainImage } = this.state;

    const selectedIndex = (thumbnailType === 'AVATAAR_AR') ? augmentedRealityIndex : selectedThumbnailIndex;
    const mediaType = Helper.getMediaType(mainImage);

    const thumbnailPayload = {
      section: 'media',
      component: 'thumbnails',
      target: mediaType,
      position: 1 + selectedThumbnailIndex,
      guid: mediaType === 'image' ? Helper.getImageGuid(mainImage) : 'n/a'
    };

    mediaClick(thumbnailPayload);
    this.setState({ overlayOpened: true, selectedThumbnailIndex: selectedIndex });
    if (typeof window !== 'undefined') {
      window.history.pushState({ page: 'MEDIAOVERLAY' }, null, '');
    }
  };

  onThumbnailEvent = (thumbnailList, images, image, thumbnail, eventType) => () => {
    const thumbnailCount = thumbnailList.length;
    const { selectedThumbnailIndex, mainImage } = this.state;
    const index = thumbnailList.findIndex((t) => t.id === thumbnail.id);
    const { totalThumbnailsToShow } = this.props;
    const isLastThumbnail = index === (totalThumbnailsToShow - 1);
    switch (eventType) {
      case 'click': {
        const currentlySelected = thumbnailList.findIndex((t, indx) => indx === selectedThumbnailIndex);
        if (index === -1 || currentlySelected === -1) return null;
        const newThumbnails = [...thumbnailList];
        this.setState({ selectedThumbnailIndex: index });

        if (isLastThumbnail && thumbnailCount > totalThumbnailsToShow) {
          this.onMainImageClick(thumbnail.type);
        } else {
          const mainAsset = mainImage || {};
          const interactiveMedia = !(Helper.isImage({ media: mainAsset })
            || Helper.isCustomColor({ media: mainAsset })
            || Helper.isManualColor({ media: mainAsset }))
            || Helper.isHotspot({ media: mainAsset });
          if (interactiveMedia) {
            const expandedPayload = {
              eventName: 'expanded-view',
              primaryCategory: 'overlay'
            };
            thumbnailClick(expandedPayload);
          }

          const mediaType = Helper.getMediaType(thumbnail);
          const payload = {
            section: 'media',
            component: 'thumbnails',
            target: mediaType,
            position: index + 1,
            guid: mediaType === 'image' ? Helper.getImageGuid(thumbnail) : 'n/a'
          };

          mediaClick(payload);
          // Clicking on a thumbnail should open the overlay UNLESS the thumbnail is a plain image
          this.setState({
            mainImage: images[index],
            thumbnails: newThumbnails,
            overlayOpened: interactiveMedia
          });
        }
        break;
      }
      case 'mouseenter': {
        this.setState({ mainImage: images[index], selectedThumbnailIndex: index });
        break;
      }
      default: break;
    }
    return null;
  };

  hideOverlay = (e) => {
    const { onAvataarButtonClick } = this.props;
    this.setState({ overlayOpened: false });

    if (onAvataarButtonClick) {
      onAvataarButtonClick({ showAvataarAR: false });
    }
  };

  renderMainImage = (media = {}, identifiers = {}) => {
    const { mainImage, selectedThumbnailIndex } = this.state;
    const {
      mainImageFetchPriority, overrideData, showAvataarDefault
    } = this.props;
    const { highResolution = [], main = [], zoom = [] } = media;
    const mainAsset = mainImage || main[selectedThumbnailIndex] || {};
    const {
      id, mediaStill, url, videoStill, type, collection, altText
    } = mainAsset;
    const highResImage = highResolution.find((image) => image.id === id);
    const zoomedAsset = highResImage || zoom.find((z) => z.id === id);
    const largeImage = zoomedAsset ? zoomedAsset.url : mainAsset.url;
    const brandNameLabel = overrideData?.brandNameLabel || identifiers.brandName;
    const galleryLabel = overrideData?.galleryLabel || identifiers.productLabel;
    const enlargeImage = Helper.isImage({ media: mainAsset });
    const alt = altText || id;
    const smallImageAlt = altText || `${brandNameLabel} ${galleryLabel}`;

    const brandImage = (image) => {
      if (!image) return null;
      return (
        <Image
          alt={alt}
          src={image}
        />
      );
    };

    const onePaintManualImage = (asset) => {
      if (!asset) return null;
      const manualColorStyle = classNames('manualSwatch',
        'sui-aspect-square',
        'sui-bg-inactive',
        'sui-flex',
        'sui-items-center',
        'sui-justify-center');
      return (
        <div
          className={manualColorStyle}
        >
          <div className="sui-text-center sui-flex sui-items-center sui-justify-center">
            <div>
              <PaintSwatches color="medium" />
              <div>No Color Preview Available</div>
            </div>
          </div>
          <div
            className="mediagallery__mainimage--paintColor sui-absolute"
          >
            {brandImage(asset?.brandLogo)}
          </div>
        </div>
      );
    };

    const onePaintRGBImage = (asset) => {
      if (!asset) return null;
      const rgb = asset?.rgb;
      return (
        <div
          className="mediagallery__mainimage--paintColor"
          style={{
            backgroundColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`
          }}
        >
          { brandImage(asset?.brandLogo) }
        </div>
      );
    };

    const magnifyLargeImage = {
      src: largeImage,
      width: highResImage ? 2000 : 1000,
      height: highResImage ? 2000 : 1000
    };
    const imageMagnifyProps = {
      smallImage: {
        alt: smallImageAlt,
        isFluidWidth: true,
        src: url
      },
      largeImage: magnifyLargeImage,
      lensStyle: {
        backgroundImage: '',
      },
      enlargedImageContainerClassName: 'mediagallery__zoom-container',
      enlargedImageContainerDimensions: {
        width: '100%',
        height: '100%'
      },
      fetchPriority: mainImageFetchPriority,
    };

    return (
      mainAsset
        ? (
          <div
            ref={this.mainImageRef}
            className="mediagallery__mainimageblock mediagallery__mainimageblock--size"
          >
            {Helper.isHotspot({ media: mainAsset })
            && (
              <HotspotTagIcon
                hotspots={mainAsset?.hotspots}
                ocrHotspots={mainAsset?.ocrHotspots}
                openOverlay
                onHotspotImageClick={() => this.onMainImageClick(type)}
                imageUrl={mainAsset?.url}
                mediaGalleryIndex={selectedThumbnailIndex}
              />
            )}
            <button
              type="button"
              className="mediagallery__mainimage--clickable"
              onClick={() => this.onMainImageClick(type)}
            >
              {Helper.isVideo({ media: mainAsset })
                  && (
                    <>
                      <Image
                        className="mediagallery__video"
                        src={videoStill}
                        alt={alt || 'video still'}
                        lazy
                        height="600"
                        width="600"
                      />
                      <BackgroundCircleIcon />
                      <PlayIcon />
                    </>
                  )}
              {Helper.isNonAvataarAR({ media: mainAsset })
                  && (
                    <>
                      <Image
                        stretchy
                        src={mediaStill}
                        alt={alt || 'AR still'}
                        lazy
                        height="600"
                        width="600"
                      />
                      <BackgroundCircleIcon />
                      <ARIcon />
                      {!Helper.isTouchEnabled() && showAvataarDefault
                      && <span className="mediagallery__cta">Click on image to view item in 3D augmented reality</span>}
                    </>
                  )}
              {Helper.isAvataarAR({ media: mainAsset })
                  && (
                    <>
                      <Image
                        stretchy
                        src={mediaStill}
                        alt={alt || '3D still'}
                        lazy
                        height="600"
                        width="600"
                      />
                      <BackgroundCircleIcon />
                      <ThreeSixtyIcon />
                      {!Helper.isTouchEnabled() && showAvataarDefault
                      && <span className="mediagallery__cta">Click on image to view item in 3D</span>}
                    </>
                  )}
              {Helper.isImage360({ media: mainAsset }) && !showAvataarDefault
                  && (
                    <>
                      <Image
                        stretchy
                        src={mediaStill}
                        alt={alt || '360 image'}
                        lazy
                        height="600"
                        width="600"
                      />
                      <BackgroundCircleIcon />
                      <ThreeSixtyIcon />
                    </>
                  )}
              {Helper.isCustomColor({ media: mainAsset }) && onePaintRGBImage(mainAsset)}
              {Helper.isManualColor({ media: mainAsset }) && onePaintManualImage(mainAsset)}
              {enlargeImage
                  && (
                    <>
                      <ReactImageMagnify {...imageMagnifyProps} />
                      <span className="mediagallery__mainimageicons--expand" />
                      {collection
                        && (
                          <div className="shopThisRoomContainer">
                            <a
                              className="shopThisRoomLink"
                              target="_blank"
                              rel="noreferrer"
                              href={collection}
                              onClick={(e) => e && e.stopPropagation()}
                            >
                              Shop This Room
                            </a>
                          </div>
                        )}
                      {!Helper.isTouchEnabled() && <span className="mediagallery__cta">Hover Image to Zoom</span>}
                    </>
                  )}
            </button>
          </div>
        )
        : null
    );
  };

  renderThumbnails = (thumbnailList = [], mainImageList = []) => {
    const { selectedThumbnailIndex } = this.state;
    const { totalThumbnailsToShow } = this.props;
    const availableThumbnails = (thumbnailList)
      .map((thumbnail, index) => ({
        ...thumbnail,
        isSelected: selectedThumbnailIndex === index
      }));
    const thumbnailCount = thumbnailList.length;
    return (
      thumbnailCount
        ? (
          <>
            {
              availableThumbnails
                .slice(0, totalThumbnailsToShow)
                .map((thumbnail, thumbnailIndex) => {
                  const isAR = Helper.isAR({ media: thumbnail });
                  const isAvataarAR = Helper.isAvataarAR({ media: thumbnail });
                  const isVideo = Helper.isVideo({ media: thumbnail });
                  const isThreesixty = Helper.isImage360({ media: thumbnail });
                  const isHotspot = Helper.isHotspot({ media: thumbnail });
                  const isCustomColor = Helper.isCustomColor({ media: thumbnail });
                  const isManualColor = Helper.isManualColor({ media: thumbnail });
                  const {
                    id, url, videoStill, mediaStill = '', altText
                  } = thumbnail;
                  const alt = altText || id;

                  let thumbnailUrl = url;
                  if (isAR) {
                    thumbnailUrl = mediaStill;
                  } else if (isVideo) {
                    thumbnailUrl = videoStill;
                  } else if (isThreesixty) {
                    thumbnailUrl = `${url}?thumb&profile=400`;
                  }

                  const isLastThumbnail = thumbnailIndex === (totalThumbnailsToShow - 1);
                  const mediaStyles = classNames(
                    'mediagallery__thumbnail',
                    { 'mediagallery__thumbnail--selected': thumbnail.isSelected }
                  );

                  const mediaProps = {
                    className: 'mediagallery__imgblock',
                    onClick: this.onThumbnailEvent(availableThumbnails, mainImageList, thumbnail, thumbnail, 'click'),
                    /* eslint-disable-next-line max-len */
                    onMouseEnter: this.onThumbnailEvent(availableThumbnails, mainImageList, thumbnail, thumbnail, 'mouseenter'),
                    /* eslint-disable-next-line max-len */
                    onMouseLeave: this.onThumbnailEvent(availableThumbnails, mainImageList, thumbnail, thumbnail, 'mouseout')
                  };

                  const isImagePath = (/^(?=.*\/productImages\/).*$/.test(thumbnailUrl));
                  const reducedThumbnailUrl = (thumbnailUrl || '').replace('_145.', '_100.');
                  const finalReducedThumbnailUrl = (reducedThumbnailUrl || '')
                    .replace('catalog/productImages/145', 'catalog/productImages/100');
                  const finalThumbnailUrl = isVideo
                    ? (thumbnailUrl || '').replace('/1280x720/match', '/88x50/match')
                    : thumbnailUrl;
                  const finalHeight = isVideo ? '50' : '88';
                  const rgb = thumbnail?.rgb;
                  return (
                    <div className={mediaStyles} key={thumbnailIndex}>
                      <button {...mediaProps} type="button">
                        {isManualColor && (
                          <div
                            className={classNames('sui-bg-inactive',
                              'sui-flex',
                              'sui-items-center',
                              'sui-justify-center',
                              'sui-w-full',
                              'sui-h-full'
                            )}
                          >
                            <div
                              className="sui-text-center sui-flex sui-items-center sui-justify-center"
                            >
                              <PaintSwatches color="medium" size="large" />
                            </div>
                          </div>
                        )}
                        {isCustomColor ? (
                          <div
                            className="mediagallery__thumbnailImageFitBrand"
                            style={{
                              backgroundColor: `rgb(${rgb.red},${rgb.green},${rgb.blue})`,
                            }}
                          >
                            <Image
                              src={thumbnail.brandLogo}
                              alt={alt}
                              lazy
                            />
                          </div>
                        ) : (
                          <>
                            <Image
                              src={isImagePath ? finalReducedThumbnailUrl : finalThumbnailUrl}
                              alt={alt || ''}
                              lazy
                              height={finalHeight}
                              width="88"
                              className="mediagallery__thumbnailImageFit"
                            />
                            {(isHotspot || isAR || isVideo || isThreesixty) && (<BackgroundCircleIcon isThumbnail />)}
                            {isHotspot && <HotspotTagIcon isThumbnail />}
                            {isAR && isAvataarAR && <ThreeSixtyIcon isThumbnail />}
                            {isAR && !isAvataarAR && <ARIcon isThumbnail />}
                            {isVideo && <PlayIcon isThumbnail />}
                            {isThreesixty && <ThreeSixtyIcon isThumbnail />}
                            {(isLastThumbnail && thumbnailCount > totalThumbnailsToShow)
                              && (
                                <>
                                  <span className="mediagallery__thumbnail--fade" />
                                  <span className="mediagallery__thumbnailicons--count">
                                    +{thumbnailCount - (totalThumbnailsToShow - 1)}
                                  </span>
                                </>
                              )}
                          </>
                        )}
                      </button>
                    </div>
                  );
                })
            }
          </>
        )
        : (
          <div className="mediagallery__placeholder">
            {new Array(6).fill('').map((value, i) => (
              <Placeholder type="rect" height="88px" width="88px" key={i} />
            ))}
          </div>
        )
    );
  };

  render() {
    const {
      collection, marqueeRef, media, overrideData, hideProductImagesLabel, product,
      configuredProductLabel, showAvataarAR, showAvataarDefault, augmentedRealityIndex
    } = this.props;
    const { identifiers, itemId = '', paintDetails } = product || {};
    const { main } = media;
    const {
      mainImage, overlayOpened, thumbnails, selectedThumbnailIndex
    } = this.state;
    if (marqueeRef && marqueeRef.current) {
      marqueeRef.current.classList.remove(`mediagalleryoverlay--${(overlayOpened ? 'closed' : 'open')}`);
      marqueeRef.current.classList.add(`mediagalleryoverlay--${(overlayOpened ? 'open' : 'closed')}`);
    }
    return (
      <>
        <Row id="media-gallery" data-component="MediaGalleryDesktop">
          <Col>
            <div className="mediagallery">
              <div ref={this.thumpRef} className="mediagallery__thumbnails">
                {this.renderThumbnails(thumbnails, main)}
              </div>
              <div ref={this.mainImageWrapperRef} className="mediagallery__mainimage">
                {this.renderMainImage(media, identifiers, paintDetails)}
              </div>
            </div>
          </Col>
        </Row>
        {
          overlayOpened && (
            <Suspense fallback={<div />}>
              <MediaGalleryOverlay
                handleClose={this.hideOverlay}
                overlayOpened={overlayOpened}
                collection={collection}
                media={media}
                product={product}
                mainAsset={mainImage || main[showAvataarAR ? augmentedRealityIndex : 0]}
                thumbnails={thumbnails}
                overrideData={overrideData}
                hideProductImagesLabel={hideProductImagesLabel}
                configuredProductLabel={configuredProductLabel}
                videoAnalyticsData={{ position: (selectedThumbnailIndex + 1).toString() }}
                itemId={itemId}
                showAvataarDefault={showAvataarDefault}
                showAvataarAR={showAvataarAR}
              />
            </Suspense>
          )
        }
      </>
    );
  }
}

MediaGalleryDesktop.propTypes = {
  augmentedRealityIndex: number,
  collection: shape({}),
  configuredProductLabel: string,
  hideProductImagesLabel: bool,
  mainImageFetchPriority: oneOf([
    'high', // Fetch the image at a high priority relative to other images.
    'low', // Fetch the image at a low priority relative to other images.
    'auto' // Indicates no preference for the fetch priority. The browser decides what is best for the user.
  ]),
  mainSuperSkuHover: arrayOf(shape({
    id: string,
    location: string,
    type: string,
    altText: string
  })),
  marqueeRef: oneOfType([
    func,
    shape({ current: any })
  ]),
  media: shape({
    main: arrayOf(shape({
      id: string,
      location: string,
      type: string,
      altText: string
    })),
    thumbnails: arrayOf(shape({
      id: string,
      location: string,
      type: string,
      altText: string
    })),
    zoom: arrayOf(shape({
      id: string,
      location: string,
      type: string,
      altText: string
    })),
    highResolution: arrayOf(shape({
      id: string,
      location: string,
      type: string,
      altText: string
    }))
  }),
  onAvataarButtonClick: func,
  overrideData: shape({
    galleryLabel: string,
    brandNameLabel: string
  }),
  product: shape({}),
  totalThumbnailsToShow: number,
  showAvataarAR: bool,
  showAvataarDefault: bool,
};

MediaGalleryDesktop.defaultProps = {
  augmentedRealityIndex: 0,
  collection: null,
  configuredProductLabel: null,
  hideProductImagesLabel: false,
  mainImageFetchPriority: 'auto',
  mainSuperSkuHover: [{
    id: '',
    location: '',
    type: '',
    altText: ''
  }],
  marqueeRef: null,
  media: {
    main: [{
      id: '',
      location: '',
      type: '',
      altText: ''
    }],
    thumbnails: [{
      id: '',
      location: '',
      type: '',
      altText: ''
    }],
    zoom: [{
      id: '',
      location: '',
      type: '',
      altText: ''
    }],
    highResolution: []
  },
  onAvataarButtonClick: null,
  overrideData: null,
  product: {},
  showAvataarAR: false,
  showAvataarDefault: false,
  totalThumbnailsToShow: 0,
};

export { MediaGalleryDesktop };
