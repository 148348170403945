import React, { useContext, useEffect, useState } from 'react';
import {
  bool, shape, string,
} from 'prop-types';
import {
  params,
  string as stringType,
  shape as shapeType,
  bool as boolType,
  useDataModel
} from '@thd-nucleus/data-sources';
import axios from 'axios';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Drawer } from '@one-thd/sui-atomic-components';
import { ARContent } from './components/ARContent/ARContent.component';
import { ARInstructions } from './components/ARInstructions/ARInstructions';
import { avataarAugmentedRealityIdsTnT } from './components/Avataar/avataar-skus';

export const AugmentedReality = ({ forwardedRef, instructions, itemId }) => {
  const experienceContext = useContext(ExperienceContext);

  const [assetLink, setAssetLink] = useState('');
  const [loadedAsset, setLoadedAsset] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [supported, setSupported] = useState(false);

  // prefetch asset
  useEffect(() => {
    if (drawerOpen && instructions) {
      axios.get(assetLink).then(() => {
        setLoadedAsset(assetLink);
      }).catch(() => {
        // if something went wrong, don't block the user - give them degraded experience
        setLoadedAsset(assetLink);
      });
    }
  }, [drawerOpen]);
  // Check for support on load
  const supportsAR = () => {
    const userAgent = window?.navigator?.userAgent?.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const anchor = window.document.createElement('a');
      if (supportsAR() || (anchor?.relList?.length && anchor.relList.supports('ar'))) {
        setSupported(true);
      }
    }
  }, []);
  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  const isAvataarOrRoomvoEnabled = () => {
    const fsAvataar = experienceContext?.configService?.['fs:isAvataarEnable'];
    const isAvataarSKU = avataarAugmentedRealityIdsTnT.indexOf(itemId) > -1;
    const isRoomVOEnabled = data?.product?.identifiers?.roomVOEnabled;
    return (fsAvataar && isAvataarSKU) || isRoomVOEnabled;
  };

  // if Avataar or Roomvo is enabled, dont render augmented reality
  if (isAvataarOrRoomvoEnabled()) return null;

  const { channel } = experienceContext;
  const isMobile = channel === 'mobile';

  if ((error && !data) || loading || !isMobile || !supported) {
    return null;
  }

  const { product } = data || {};
  const { media } = product || {};
  const { augmentedRealityLink } = media || {};
  const { usdz } = augmentedRealityLink || {};

  if (!usdz) {
    return null;
  }
  if (!assetLink) {
    setAssetLink(`https://assets.homedepot-static.com/mobile-apps/3d/usdz/${usdz}`);
  }
  return (
    <div data-component="AugmentedReality">
      <ARContent
        assetLink={assetLink}
        forwardedRef={forwardedRef}
        onClick={instructions ? () => setDrawerOpen(true) : null}
      />
      {instructions && (
        <Drawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <ARInstructions loadedAsset={loadedAsset} onViewerClick={() => setDrawerOpen(false)} />
        </Drawer>
      )}
    </div>
  );
};

AugmentedReality.displayName = 'AugmentedReality';

AugmentedReality.dataModel = {
  product: params({
    itemId: stringType().isRequired()
  })
    .shape({
      dataSources: stringType(),
      identifiers: shapeType({
        roomVOEnabled: boolType()
      }),
      media: shapeType({
        augmentedRealityLink: shapeType({
          usdz: stringType()
        })
      })
    }),
};

AugmentedReality.propTypes = {
  forwardedRef: shape({}),
  instructions: bool,
  itemId: string.isRequired
};

AugmentedReality.defaultProps = {
  forwardedRef: null,
  instructions: false
};
