import React from 'react';
import {
  extend,
  params,
  shape as shapeType,
  arrayOf,
  string as stringType
} from '@thd-nucleus/data-sources';
import { MediaHotspots } from '@thd-olt-component-react/product-hotspots';

import { MediaGalleryDesktop } from './MediaGalleryDesktop';
import { dataModel } from './dataModel';
import { mediaGalleryDesktopPropTypes, mediaGalleryDesktopDefaultProps } from './MediaGalleryPropTypes';

export const MediaGalleryWithHotspotsDesktop = (props) => {
  return (
    <MediaGalleryDesktop {...props} />
  );
};

MediaGalleryWithHotspotsDesktop.displayName = 'MediaGalleryWithHotspotsDesktop';

MediaGalleryWithHotspotsDesktop.propTypes = mediaGalleryDesktopPropTypes;

MediaGalleryWithHotspotsDesktop.defaultProps = mediaGalleryDesktopDefaultProps;

MediaGalleryWithHotspotsDesktop.dataModel = extend({}, {
  product: params({ itemId: stringType().isRequired() }).shape({
    media: shapeType({
      images: arrayOf(shapeType({
        hotspots: arrayOf(shapeType({
          coordinate: shapeType({
            xCoordinate: stringType(),
            yCoordinate: stringType()
          }),
          omsIDs: arrayOf(stringType()),
        })),
        ocrHotspots: arrayOf(shapeType({
          coordinate: shapeType({
            xCoordinate: stringType(),
            yCoordinate: stringType()
          }),
          omsIDs: arrayOf(stringType()),
        })),
      }))
    })
  })
}, dataModel, MediaHotspots);
